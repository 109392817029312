import { graphql } from "gatsby"
import React from "react"
import { BonusComicContainer } from "../components/BonusComicContainer"
import { Comic } from "../components/Comic"
import Layout from "../components/layout"
// @ts-ignore
import SEO from "../components/seo"
import { Post } from "./types"

interface Props {
    data: {
        markdownRemark: Post
        site: { siteMetadata?: { title: string } }
        previous?: Partial<Post>
        next?: Partial<Post>
        fields?: { slug: string }
    }
    location: { pathname: string }
}

const BlogPostTemplate = ({ data, location }: Props) => {
    const post = data.markdownRemark
    const frontmatter = post?.frontmatter || {}
    const siteTitle = data.site.siteMetadata?.title || ``
    const fields = data.fields || { slug: "/" }

    return (
        <Layout location={location} title={siteTitle}>
            <SEO
                title={frontmatter?.title || ""}
                description={frontmatter?.description || ""}
            />
            <Comic
                post={post}
                previous={data.previous}
                next={data.next}
                fields={fields}
            />
            <BonusComicContainer
                bonus={frontmatter?.bonus || frontmatter?.bonusStatic}
            />
        </Layout>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug(
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            html
            fields {
                slug
            }
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                tags
                bonus {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                bonusStatic
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
            }
        }
    }
`
